import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby';

// Components
import ServiceCard from '../../components/card/Service';

const ServicesSection = ({ site, city, sectionStyle }) => {

  const query = useStaticQuery(graphql`
    query {
      services: allMdx(
        filter: {
          fileAbsolutePath: { regex: "/data/services/" },
          frontmatter: { home: { eq: true }, active: { eq: true } }
        },
        sort: {fields: frontmatter___order, order: ASC}
      )
      {
        nodes {
          frontmatter {
            title
            description
            image {
              icon {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              card {
                childImageSharp {
                  gatsbyImageData
                  fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt
              credit {
                text
                link
              }
            }
            home
            active
            date(formatString: "D MMMM YYYY", locale: "fr")
          }
          id
          slug
        }
      }
      backgroundImage: file(relativePath: { eq: "backgrounds/services-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set ImageData.
  const backgroundImage = query.backgroundImage.childImageSharp.fluid

  return (
    <>
      {sectionStyle === "1" &&
        <BackgroundImage
          id="services" 
          Tag="section"
          className="bg-silver-light"
          fluid={backgroundImage}
        >
          <div className="container">
            <div className="section-title">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                      <div className="title-wrapper">
                        <h2 className="title">Nos <span className="text-theme-colored1">services</span></h2>
                        <div className="title-seperator-line"></div>
                        <div className="paragraph">
                          <p>Notre équipe compétente et qualifiée saura vous apporter toutes les informations et le savoir-faire pour la bonne réalisation de votre projet.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                {query.services.nodes.map(service => (
                  <ServiceCard key={service.id} data={service} cardStyle="1" cardClassName="col-md-6 col-lg-6 col-xl-4" />
                ))}
              </div>
              <h5 className="text-center mt-70 mb-30">Notre savoir-faire est notre valeur ajoutée n°1!</h5>
              <div className="tm-sc tm-sc-button text-center">
                <Link to={`/contact/`} className="btn btn-theme-colored1 btn-round smooth-scroll-to-target">Demandez un devis</Link>
              </div>
            </div>
          </div>
        </BackgroundImage>
      }
      {sectionStyle === "2" &&
        <BackgroundImage
          id="services" 
          Tag="section"
          className="bg-silver-light"
          fluid={backgroundImage}
        >
          <div className="container">
            <div className="section-title">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                      <div className="title-wrapper">
                        <h2 className="title">Nos <span className="text-theme-colored1">services</span></h2>
                        <div className="title-seperator-line"></div>
                        <div className="paragraph">
                          <p>Notre équipe compétente et qualifiée saura vous apporter toutes les informations et le savoir-faire pour la bonne réalisation de votre projet.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="row">
                {query.services.nodes.map(service => (
                  <ServiceCard key={service.id} data={service} cardStyle="2" cardClassName="col-md-6 col-lg-6 col-xl-4 mb-30" />
                ))}
              </div>
            </div>
          </div>
        </BackgroundImage>
      }
    </>
  )
}

export default ServicesSection