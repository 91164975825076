import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const Transformations = ({ site }) => {

  const query = useStaticQuery(graphql`
    query {
      beforeImage: file(relativePath: { eq: "transformations/transformation-avant-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      afterImage: file(relativePath: { eq: "transformations/transformation-apres-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      <section id="transformations">
        <div className="container pt-90">
          <div className="section-title">
            <div className="row justify-content-md-center">
              <div className="col-md-10">
                <div className="text-center mb-60">
                  <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                    <div className="title-wrapper">
                      <h2 className="title">Transformons <span className="text-theme-colored1">votre projet</span> en <span className="text-theme-colored2">réussite</span></h2>
                      <div className="title-seperator-line"></div>
                      <div className="paragraph">
                        <p>Que votre projet soit personnel ou professionnel notre équipe expérimentée et dynamique mettra tout en œuvre afin de l'accomplir dans les meilleurs conditions et délais.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-content">
            <div className="row">
              <div className="col-sm-12">
                <ReactCompareSlider
                  itemOne={
                    <ReactCompareSliderImage src={query.beforeImage.childImageSharp.fluid.srcWebp} srcSet={query.beforeImage.childImageSharp.fluid.srcSetWebp} alt={`Avant rénovation de la façade d'une maison par ${site.siteMetadata.title} à Roanne`} />
                  }
                  itemTwo={
                    <ReactCompareSliderImage src={query.afterImage.childImageSharp.fluid.srcWebp} srcSet={query.afterImage.childImageSharp.fluid.srcSetWebp} alt={`Après rénovation de la façade d'une maison par ${site.siteMetadata.title} à Roanne`} />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Transformations