import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Tilty from 'react-tilty';

const InterventionZoneSection = ({ site, sectionStyle }) => {
  return (
    <>
      {sectionStyle === "1" &&
        <section id="intervention-zone" className="bg-white-f5">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <Tilty className="tilty" glare scale={1.05} maxGlare={0.5}>
                    <StaticImage src="../../images/intervention-zone/zone-d-intervention-pcc-batiment.png" alt={`Zone d'intervention de ${site.siteMetadata.title} dans un rayon de 40 km autour de Roanne : Le Coteau, Riorges, Perreux, Mably, Commelle-Vernay, Villerest, Saint-Léger-sur-Roanne, Parigny, Ouches, Pouilly-les-Nonains, Lentigny`} />
                  </Tilty>
                </div>
                <div className="col-md-12 col-lg-6">
                  <h3 className="line-bottom line-bottom-theme-colored6 mt-md-30">Notre zone d'intervention</h3>
                  <p>{site.siteMetadata.title} se situe à Roanne et intervient dans un rayon de 40 km autour pour vos projets en plâtrerie, peinture, décoration, aménagement, ravalement de façade, isolation intérieure et extérieure.</p>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="tm-sc tm-sc-unordered-list list-style2">
                        <ul>
                          <li>Roanne</li>
                          <li>Le Coteau</li>
                          <li>Riorges</li>
                          <li>Perreux</li>
                          <li>Mably</li>
                          <li>Commelle-Vernay</li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="tm-sc tm-sc-unordered-list list-style2">
                        <ul>
                          <li>Villerest</li>
                          <li>Saint-Léger-sur-Roanne</li>
                          <li>Parigny</li>
                          <li>Ouches</li>
                          <li>Pouilly-les-Nonains</li>
                          <li>Lentigny</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-30">
                    <div className="col-sm-12">
                      <div className="tm-sc tm-sc-button float-left mr-20">
                        <Link to={`/contact/`} className="btn btn-theme-colored1">Contactez-nous</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default InterventionZoneSection