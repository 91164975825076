import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const CTASection = ({ site, sectionStyle }) => {

  const query = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "backgrounds/cta-pcc-batiment.png" }) {
        childImageSharp {
          fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  // Set ImageData.
  const backgroundImage = query.backgroundImage.childImageSharp.fluid
  const backgroundImageStack = [
    backgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <>
      {sectionStyle === "1" &&
        <BackgroundImage
          id="counts" 
          Tag="section"
          className="static-cta"
          fluid={backgroundImageStack}
        >
          <div className="container">
            <div className="section-content text-center">
              <div className="row justify-content-md-center">
                <div className="col-md-8">
                  <h5 className="text-theme-colored2">Vous avez une question ?</h5>
                  <h2 className="text-white">Parlez-nous de votre projet, nous sommes à votre écoute</h2>
                  <div className="tm-sc tm-sc-button text-center mt-30">
                    <Link to={`/contact/`} className="btn btn-theme-colored2 btn-lg btn-round">Contactez-nous</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      }
      {sectionStyle === "2" &&
        <section>
          <div className="container pt-0">
            <div className="row">
              <div className="col-md-12">
                <div className="page type-page status-publish hentry">
                  <div className="entry-content">
                    <div className="page-content">
                      <div className="row bg-theme-colored1 p-80 section-typo-light">
                        <div className="call-to-action tm-sc tm-sc-call-to-action text-center">
                          <div className="cta-content">
                            <h3>Et nous avons LA solution !</h3>
                          </div>
                          <p>Un processus unique de réalité virtuelle afin de vous proposer une immersion totale au cœur de vos projets. Qualité, mouvements, sons, textures et finition sont mis en avant afin de vous donner un rendu final de vos travaux le plus réaliste possible.</p>
                          <AnchorLink className="btn btn-outline-light btn-outline" href="#virtual-realities" aria-label="top">Exemples de réalisations</AnchorLink>
                        </div>
                      </div>
                      <div className="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}
  
export default CTASection