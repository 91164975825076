import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay, EffectFade} from 'swiper';
import { Link } from 'gatsby';

const HeroSection = ({ site, sectionStyle }) => {
  return (
    <>
      {sectionStyle === "1" &&    
        <section id="hero" className="">
          <div className="container-fluid p-0">
            <div className="row">
              <div className="col">
                <Swiper
                  modules={[Navigation, Autoplay, EffectFade]}
                  navigation={true}
                  autoplay={{ delay: 5000}}
                  effect="fade"
                  loop={true}
                  fadeEffect= {{crossFade: true }}
                  spaceBetween={30}
                  className="swiper"
                >
                  <SwiperSlide className="swiper-slide carousel-inner">
                    <div className="carousel-item active">
                      <StaticImage src="../../images/sliders/specialiste-batiment-roanne-pcc-batiment.png" className="d-block w-100" alt={site.siteMetadata.title + ' est le spécialiste du bâtiment à Roanne'} />
                      <div className="position-absolute top-50 start-50 translate-middle text-center">
                        <h2 className="mb-20 text-white"><span className="text-theme-colored2">Le savoir et la qualité</span></h2>
                        <h6 className="mb-20 text-white">{site.siteMetadata.title} est le spécialiste du bâtiment à Roanne</h6>
                        <div className="tm-sc tm-sc-button float-left mr-20">
                        <Link to={`/contact/`} className="btn btn-theme-colored1">Contactez-nous</Link>
                      </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide carousel-inner">
                    <div className="carousel-item active">
                      <StaticImage src="../../images/sliders/equipe-competente-qualifee-roanne-pcc-batiment.png" className="d-block w-100" alt={site.siteMetadata.title + ' met une équipe compétente et qualifiée à votre disposition'}/>
                      <div className="position-absolute top-50 start-50 translate-middle text-center">
                        <h2 className="mb-20 text-white">Une équipe <span className="text-theme-colored2">compétente</span> et <span className="text-theme-colored1">qualifiée</span></h2>
                        <h6 className="mb-20 text-white">Qui saura vous apporter toutes les informations et le savoir-faire pour la bonne réalisation de votre projet.</h6>
                        <div className="tm-sc tm-sc-button float-left mr-20">
                        <AnchorLink className="btn btn-theme-colored1" href="#realisations" aria-label="top">Découvrir les services</AnchorLink>
                      </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide carousel-inner">
                    <div className="carousel-item active">
                      <StaticImage src="../../images/sliders/contruction-renovation-roanne-pcc-batiment.png" className="d-block w-100" alt={site.siteMetadata.title + ' a réalisé de nombreux projets construction ou rénovation à Roanne'}/>
                      <div className="position-absolute top-50 start-50 translate-middle text-center">
                        <h2 className="mb-20 text-white"><span className="text-theme-colored1">Construction</span> ou <span className="text-theme-colored2">rénovation</span></h2>
                        <h6 className="mb-20 text-white">{site.siteMetadata.title} a réalisé de nombreux projets à Roanne</h6>
                        <div className="tm-sc tm-sc-button float-left mr-20">
                        <AnchorLink className="btn btn-theme-colored1" href="#realisations" aria-label="top">Voir les réalisations</AnchorLink>
                      </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide carousel-inner">
                    <div className="carousel-item active">
                      <StaticImage src="../../images/sliders/depuis-30-ans-au-service-particuliers-professionnels-pcc-batiment.png" className="d-block w-100" alt={site.siteMetadata.title + ' met son savoir-faire au service des particuliers et des professionnels'} />
                      <div className="position-absolute top-50 start-50 translate-middle text-center">
                        <h2 className="mb-20 text-white"><span className="text-theme-colored1">Depuis 30 ans</span></h2>
                        <h6 className="mb-20 text-white">{site.siteMetadata.title} met son savoir-faire au service des particuliers et des professionnels</h6>
                        <div className="tm-sc tm-sc-button float-left mr-20">
                        <Link to={`/contact/`} className="btn btn-theme-colored1">Contactez-nous</Link>
                      </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide className="swiper-slide carousel-inner">
                    <div className="carousel-item active">
                      <StaticImage src="../../images/sliders/realisez-projet-platrerie-peinture-decoration-amenagement-ravalement-isolation-exterieure-roanne-pcc-batiment.png" className="d-block w-100" alt={site.siteMetadata.title + ' prospose des services de plâtrerie, peinture, décoration, aménagement, ravalement de façade, isolation intérieure et extérieure'} />
                      <div className="position-absolute top-50 start-50 translate-middle text-center">
                        <h2 className="mb-20 text-white">Réalisez vos projets avec <span className="text-theme-colored2">{site.siteMetadata.title}</span></h2>
                        <h6 className="mb-20 text-white">Plâtrerie, peinture, décoration, aménagement, ravalement de façade, isolation intérieure et extérieure</h6>
                        <div className="tm-sc tm-sc-button float-left mr-20">
                        <AnchorLink className="btn btn-theme-colored1" href="#realisations" aria-label="top">Découvrir les services</AnchorLink>
                      </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default HeroSection