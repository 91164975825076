import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Tilty from 'react-tilty';

const RSESection = ({ site, sectionStyle }) => {
  return (
    <>
      {sectionStyle === "1" &&
        <section id="rse" className="bg-white-f5">
          <div className="container">
            <div className="section-content">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <h3 className="line-bottom line-bottom-theme-colored6 mt-md-30">Notre engagement <span className="text-theme-colored2">RSE</span></h3>
                  <p>{site.siteMetadata.title} se distingue par son expertise et son engagement envers la qualité et le développement durable. Au cœur de son activité réside la Responsabilité Sociétale des Entreprises (RSE) :</p>
                  <div className="tm-sc tm-sc-unordered-list list-style2">
                    <ul>
                      <li>Nouveaux véhicules de fonction hybrides</li>
                      <li>Mise en place de visio' avec les partenaires lorsque le déplacement est conséquent</li>
                      <li>Utilisation de tasses réutilisables</li>
                      <li>Gestion stricte des déchets de chantier</li>
                      <li>Utilisation d'éclairages LED et de détecteurs de mouvement dans les parties dépôts</li>
                      <li>Appareils électroniques reconditionnés</li>
                      <li>Projet de système de récupération d'eaux de pluie (en cours)</li>
                      <li>Dématérialisation et digitalisation afin de supprimer un maximum de papier et favoriser la transparence</li>
                    </ul>
                  </div>
                  <div className="row mt-30">
                    <div className="col-sm-12">
                      <div className="tm-sc tm-sc-button float-left mr-20">
                        <Link to={`/services/`} className="btn btn-theme-colored1">Découvrez nos services</Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-6 mt-3">
                  <Tilty className="tilty" glare scale={1.05} maxGlare={0.5}>
                    <StaticImage src="../../images/rse/salle-de-repos-pcc-batiment-roanne.png" alt={`Salle de repos pour les employés de ${site.siteMetadata.title} à Roanne.`} width={560} />
                  </Tilty>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    </>
  )
}

export default RSESection