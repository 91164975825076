import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// Components
import Layout from '../components/layout/Layout';
import HeroSection from '../components/section/Hero';
import AboutSection from '../components/section/About';
//import NewAddressSection from '../components/section/NewAddress';
import CountsSection from '../components/section/Counts';
import ServicesSection from '../components/section/Services';
import TransformationsSection from '../components/section/Transformations';
import CTASection from '../components/section/CTA';
import RealisationsSection from '../components/section/Realisations';
import TestimonialsSection from '../components/section/Testimonials';
import PartnersSection from '../components/section/Partners';
import RSESection from '../components/section/RSE';
import TeamSection from '../components/section/Team';
import InterventionZoneSection from '../components/section/InterventionZone';

const IndexPage = () => {

  const query = useStaticQuery(graphql`
    query {
      site: site {
        siteMetadata {
          title
          description
          addressStreet
          addressPostalCode
          addressCity
          addressCountry
          addressGoogleDirection
          email
          telephone
          telephoneIndex
          schedules
          socialNetworks {
            facebook
            instagram
            linkedIn
          }
          author {
            name
            url
            creator
            image {
              url
            }
          }
          publisher {
            name
            url
            logo {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      pageTitle={`${query.site.siteMetadata.title} - Plâtrerie-peinture, décoration, aménagement, isolation intérieure et extérieure à Roanne`}
      headerClassName="header header-layout-type-header-2rows"
    >
      <HeroSection site={query.site} sectionStyle="1" />
      <AboutSection site={query.site} sectionStyle="1" />
      {/*<NewAddressSection site={query.site} sectionStyle="1" />*/}
      <CountsSection site={query.site} sectionStyle="1" />
      <ServicesSection site={query.site} sectionStyle="1" />
      <TransformationsSection site={query.site} />
      <CTASection site={query.site} sectionStyle="1" />
      <RealisationsSection site={query.site} sectionStyle="1" />
      <TestimonialsSection site={query.site} sectionStyle="1" />
      <PartnersSection site={query.site} sectionStyle="1" />
      <RSESection site={query.site} sectionStyle="1" />
      <TeamSection site={query.site} sectionStyle="1" />
      <InterventionZoneSection site={query.site} sectionStyle="1" />
    </Layout>
  )
}

export default IndexPage
